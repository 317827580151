<template lang="pug">
div.course-item
  CMCourseCard(:item="course" row)
    span.course-item__title
      slot
      span {{ course._date | formattedDateWithDDD }}
        template(v-if="course.date && course.name") -
        | {{ course.name }}
  //course-counts(:item="course").course-item__counts
</template>

<script>
import CMCourseCard from "@/app/admin/modules/course_managment/components/q-management/CMCourseCard.vue";

export default {
  props: {
    activeItems: Array,
    course: Object
  },

  components: {
    CMCourseCard,
    courseCounts: () => import('@/app/admin/modules/course_managment/components/СMCourseCounts.vue')
  }
}
</script>

<style lang="scss" scoped>
.course-item {
  &__title {
    display:flex;
    align-items: center;
    color: #232428;

    &::v-deep {
      .v-input--selection-controls {
        padding-top: 0;
      }
    }
  }
}
</style>