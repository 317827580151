<script>
import api from "@/services/api/api-courses"
import Svc from "@/app/admin/models/courseService"
import CachedSvc from "@/app/admin/models/courseServiceCached"
import CourseModel, { CourseModelDetailed } from '../../../../models/courseModel'

export default {
  name: 'CourseChoose',
  render() {
    return this.$scopedSlots.default({
      loadCoursesDatesByLocation: this.loadCoursesDatesByLocation,
      loading: this.loading,
      courses: this.courses
    })
  },

  data: () => ({
    svc: new CachedSvc(
      new Svc(api),
    ),
    loading: false,
    courses: []
  }),

  methods: {
    async loadCoursesDatesByLocation(newLocation) {
      let course = new CourseModelDetailed()
      course.city = newLocation
      this.$emit('input:course', course)
      this.loadData(newLocation)
    },

    async loadData(newLocation) {
      try {
        this.loading = true
        let res = await this.svc.getCoursesDetailedBy(newLocation)
        this.courses = res
      } catch (error) {
        console.log(error)
      }
      finally {
        this.loading = false
      }
    },
  }
}
</script>