import { render, staticRenderFns } from "./CandidateBulkReservationMenuItem.vue?vue&type=template&id=76352564&functional=true&lang=pug&"
import script from "./CandidateBulkReservationMenuItem.vue?vue&type=script&lang=js&"
export * from "./CandidateBulkReservationMenuItem.vue?vue&type=script&lang=js&"
import style0 from "./CandidateBulkReservationMenuItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports