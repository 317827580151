<template functional lang="pug">
p(
  v-on="listeners"
  :class="{'reservation-menu-item_active': props.active}"
).reservation-menu-item
  slot
</template>

<script>
export default {
  props: {
    active: Boolean
  }
}
</script>

<style lang="scss">
.reservation-menu-item {
  position: relative;
  margin-bottom: 0 !important;
  color: #232323;
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }

  &_active {
    color: $primary-color;
    &:before {
      position: absolute;
      bottom: 0;
      left: -10px;
      width: 3px;
      height: 100%;
      background-color: $primary-color;
      content: "";
    }
  }
}
</style>