<template lang="pug">
v-dialog(
  v-model="showing"
  fullscreen
  hide-overlay
  scrollable
)
  template(v-slot:activator="{on}")
    v-btn(v-on="on" color="primary" data-test="reservation-multiple" :disabled="disabled") Add multiple

  v-card(data-test="reservation-multiple-body")
    v-toolbar(color="primary" dark)
      v-btn(icon @click="hideShowing")
        v-icon mdi-close
      v-toolbar-title Add Requests
    v-card-text
      v-row
        reservation-type(:courseType="courseType" @click:type="selectCourseType")
        v-col.bulk-item(cols="2")
          v-list(flat)
            v-subheader(v-if="isOffline") SELECT COURSE LOCATION
            v-subheader(v-if="isOnline") SELECT EXAM LOCATION
          template(v-if="isOffline")
            menu-item(v-for="location in courseLocations"
              :key="location.ID"
              @click="loadCoursesByLocation(location.ID)"
              :active="location.ID === city"
            ) {{ location.name }}
          template(v-if="isOnline")
            menu-item(v-for="location in crmExamLocations"
              :key="location"
              @click="loadOnlineLocation(location)"
              :active="location === city"
            ) {{ location }}
        v-col.bulk-item
          v-list(flat)
            v-subheader ADD COURSE
              CMServiceProvider
                showingProvider(v-slot="showingProvider")
                  examDataStore(v-slot="examStore" :key="`${showingProvider.showing}showingrovider`")
                    formErrorsProvider(v-slot="errors")
                      createCourseExam(
                        v-slot="courseExam"
                        :formErrors="errors.formErrors"
                        @create="addCourse"
                        @end="showingProvider.toggle"
                      )
                        courseExamValidator(
                          :formErrors="errors.formErrors"
                          v-slot="courseExamValidator"
                          @validated="courseExam.checkValidatorBeforeSend"
                        )
                          createCourseProvider(
                            v-slot="course"
                            :formErrors="errors.formErrors"
                            @create="addCourse"
                            @end="showingProvider.updateShowing(false)"
                          )
                            courseValidator(:formErrors="errors.formErrors" @validated="course.checkValidatorBeforeSend" v-slot="validator")
                              v-dialog(
                                max-width="400px"
                                :value="showingProvider.showing"
                                @input="showingProvider.updateShowing"
                                :key="`${showingProvider.showing}showingrovider`"
                              )
                                template(v-slot:activator="{on}")
                                  <!-- v-btn(icon v-on="on" color="primary" x-small :disabled="!city")
                                    v-icon(size="24") mdi-plus -->
                                template(v-slot:default="dialogDefault")
                                  modal-body(
                                    :key="`${showingProvider.showing}course`"
                                    :editData="course.formData"
                                    :formErrors="errors.formErrors"
                                    :showing="showingProvider.showing"
                                    @back="showingProvider.toggle"
                                    title="Create course"
                                  )
                                    v-divider
                                    div.pt-2.pb-3.d-flex.justify-end
                                      v-switch(v-model="examStore.formData.rangeMode" label="Range mode").range-switch
                                    h2.bulk-exam-title Create exam
                                    exam-body(
                                      :formData="examStore.formData"
                                      :formErrors="errors.formErrors"
                                      :showing="showingProvider.showing"
                                      isCreateExam
                                    )
                                    template(v-slot:actions="modalProps")
                                      v-spacer
                                      v-btn(outlined width="80" @click="showingProvider.toggle").btn-common Cancel
                                      v-btn(
                                        color="primary"
                                        width="80"
                                        :loading="course.processing || courseExam.processing"
                                        @click="validator.validationBeforeSend({data: modalProps.formData})"
                                        data-test="cm-create-submit"
                                      ) Add

                                      v-btn(
                                        color="primary"
                                        :loading="course.processing || courseExam.processing"
                                        @click="courseExamValidator.validationBeforeSend({data: modalProps.formData, examData: examStore.formData})"
                                        data-test="cm-create-submit"
                                      ) Add with exam
            search(
              v-model="search"
              placeholder='Search by course'
              :class="{'hide-bulk-search': !city}"
            ).full-width
          div
            p(v-if="city < 0") Please, select city to load courses
            p(v-if="courseLoadingErr") {{ courseLoadingErr }}
            courseItem(
              v-for="(course, index) in coursesFiltered"
              :key="course.ID"
              :course="course"
              :activeItems="activeItems"
              @update:checkbox="activeItems = $event"
            )
              v-checkbox(
                v-model="activeItems"
                :disabled="loadingExams"
                @click.native="selectWithShiftkey({event: $event, item: course, index: index})"
                @change="switchExamShowing(course, $event)" :value="isOffline ? course.ID : course.ID + city" hide-details
                data-test="reservation-bulk-course-checkbox"
              ).mt-0

            .d-flex.align-center.fill-height.justify-center.preloader-courses(v-if="coursesLoaded")
              v-progress-circular(indeterminate size=64 color="warning")

        v-col
          CMServiceProvider
            span
              v-list(flat)
                v-subheader ADD EXAM
                  config-create-provider(@create="addExam(activeItems[0], $event)" v-slot="create")
                    config-create-validator(@validated="create.checkValidatorBeforeSend" v-slot="validator")
                      v-dialog(
                        max-width="400px"
                        :value="create.showing"
                        @input="create.updateShowing"
                      )
                        template(v-slot:activator="{on}")
                          <!-- v-btn(icon v-on="on" color="primary" x-small :disabled="activeItems.length != 1")
                            v-icon(size="24") mdi-plus -->

                        exam-modal-body(
                          :editData="create.formData"
                          :formErrors="create.formErrors"
                          :showing="create.showing"
                          @back="create.toggle"
                          title="Add exam"
                          isCreateExam
                        )
                          template(v-slot:actions="modalProps")
                            v-spacer
                            v-btn(outlined width="80" @click="create.toggle").btn-common Cancel
                            v-btn(
                              color="primary"
                              width="80"
                              :loading="create.processing"
                              data-test="exam-create"
                              @click="validator.validationBeforeSend({data: modalProps.formData, courseID: activeItems[0]})"
                            ).btn-common Add

              .d-flex.align-center.fill-height.justify-center(v-if="loadingExams")
                v-progress-circular(indeterminate size=64 color="warning")
              p(v-if="!exams.length && activeItems.length") Exams not found.
              div
                exam(:exam="exam" v-for="exam in exams" :key="exam.ID")
                  v-checkbox(
                    v-model="activeExams"
                    :value="exam.ID"
                    data-test="reservation-bulk-exam-checkbox"
                    hide-details
                  ).mt-0

    v-card-actions
      div.full-width
        v-divider
        .d-flex.mt-3
          v-spacer
          v-btn(outlined color="primary" @click="hideShowing") cancel
          v-btn(color="primary" @click="save" data-test="reservation-bulk-save" :disabled="!activeExams.length") save
</template>

<script>
import {mapGetters} from "vuex"
import CourseChooseDetailed from "./CandidateCourseChooseDetail.vue"
import CourseItem from "./CandidateCourseItem.vue"
import ModalBody from '@/app/admin/modules/course_managment/components/cm_course/CMCourseBody'
import CourseSvc from "@/app/admin/models/courseService"
import CachedCourseSvc from "@/app/admin/models/courseServiceCached"
import courseApi from "@/services/api/api-courses"
import Svc from '@/app/admin/modules/course_managment/core/cm-service'
import FormattedTextExam from '../../core/models/formattedTextExam.js'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import ReservationType from "./bulk/CandidateBulkReservationType.vue"
import { COURSE_TYPE } from '../../core/candidates.const'
import { COURSE_LOCATION_ONLINE } from '@/util/const'
import MenuItem from "./bulk/CandidateBulkReservationMenuItem.vue"


export default {
  props: {
    disabled: Boolean,
    licenseTypes: Array
  },

  mixins: [featureFlagsMixin],

  data: () => ({
    showing: false,
    city: null,
    courseSvc: new CachedCourseSvc(
      new CourseSvc(courseApi)
    ),
    courseType: '',
    courses: [],
    coursesLoaded: false,
    courseLoadingErr: '',
    activeItems: [],
    examsMap: {},
    activeExams: [],
    cityMap: {},
    svc: new Svc(),
    loadingExams: false,
    search: ''
  }),

  computed: {
    ...mapGetters({
      _courseLocations: 'crmCourseCities/items',
      crmExamLocations: 'crmExamLocation/items'
    }),

    courseLocations() {
      return this._courseLocations.filter(location => location.ID !== COURSE_LOCATION_ONLINE.ID)
    },

    exams() {
      let exams = []
      for(let courseID in this.examsMap) {
        exams = [...exams, ...this.examsMap[courseID]]
      }
      return exams.sort((a, b) => new Date(a.date) - new Date(b.date))
    },

    coursesFiltered() {
      if(this.search.length < 2) return this.courses
      return this.courses.filter(course => {
        if (course.date.includes(this.search)) return true
        if (course.name.toLowerCase().includes(this.search.toLowerCase())) return true
        return false
      } )
    },

    isOffline() {
      return this.courseType === COURSE_TYPE.OFFLINE
    },

    isOnline() {
      return this.courseType === COURSE_TYPE.ONLINE
    }
  },

  methods: {
    hideShowing() {
      this.showing = false
      this.reset()
    },

    reset() {
      this.city = null
      this.courseType = ''
      this.courses = []
      this.activeExams = []
      this.activeItems = []
      this.examsMap = {}
      this.cityMap = {}
    },

    selectCourseType(type) {
      this.courseType = type
      this.courses = []
    },

    loadOnlineLocation(city) {
      this.city = city
      if(this.courses.length) {
        let courses = this.courses
        this.coursesLoaded = true
        setTimeout(() => {
          this.courses = courses
          this.coursesLoaded = false
        }, 1000);
      }
      this.loadCourses(COURSE_LOCATION_ONLINE.ID)
      this.returnSelectedCourses(COURSE_LOCATION_ONLINE.ID)
    },

    loadCoursesByLocation(cityID) {
      this.city = cityID
      this.loadCourses(cityID)
      this.returnSelectedCourses(cityID)
    },

    async loadCourses(cityID) {
      this.coursesLoaded = true
      try {
        this.courseLoadingErr = ''
        this.courses = []
        let res = await this.courseSvc.getCoursesDetailedBy(cityID)
        if(!res.length) this.courseLoadingErr = 'Not has any course on this date.Please add new'
        this.courses = res
      } catch (error) {
        console.log(error)
      }
      finally {
        this.coursesLoaded = false
      }
    },

    returnSelectedCourses(cityID) {
      if(!(cityID in this.cityMap)) return
      this.activeItems = Object.values(this.cityMap[cityID]).map(item => item.ID)
    },

    addCourse(course) {
      this.loadCourses(this.city)
    },

    async switchExamShowing(course, ev) {
      let courseID = this.courseID(course)
      this.saveCityMap(course)
      if(courseID in this.examsMap) {
        this.examsMap[courseID].forEach(exam => {
          let examIndex = this.activeExams.findIndex(activeExam => activeExam === exam.ID)
          if(examIndex > -1) this.activeExams.splice(examIndex, 1)
        })
        return await this.$delete(this.examsMap, courseID)
      }
      let exams = await this.loadExams(course.ID)

      if(this.licenseTypes && this.licenseTypes.length) {
        exams = exams.filter(exam => !exam.licenseType ? true : this.licenseTypes.includes(exam.licenseType))
      }

      if(course.reservable_license_types.length) {
        exams = exams.filter(exam => course.reservable_license_types.includes(exam.licenseType));
      }

      if(this.isOnline) exams = exams.map(exam =>  new FormattedTextExam({
        ...exam,
        location: this.city
      }).convert())
      this.$set(this.examsMap, courseID, exams)
      let examsIds = exams.map(exam => exam.ID)
      this.activeExams = [...this.activeExams, ...examsIds]
      await this.$nextTick()
    },

    courseID(course) {
      return this.isOffline ? course.ID : course.ID + this.city
    },

    saveCityMap(course) {
      let courseID = this.courseID(course)
      if(this.city in this.cityMap) {
        if(courseID in this.cityMap[this.city]) return delete this.cityMap[this.city][courseID]
        this.cityMap[this.city][courseID] = course
        return
      }
      this.cityMap[this.city] = {
        [courseID]: course
      }
    },

    async loadExams(courseID) {
      try {
        this.loadingExams = true
        let res = await this.svc.getConfig(courseID)
        return res.map(item => new FormattedTextExam(item).convert())
      } catch (error) {
        console.log(error)
        return Promise.reject(error)
      }
      finally {
        this.loadingExams = false
      }
    },

    addExam(courseID, exam) {
      this.examsMap[courseID].unshift(
        new FormattedTextExam(exam).convert()
      )
      this.activeExams.push(exam.ID)
    },

    openEditingExam(exam, callback) {
      let _exam = {...exam}
      callback(_exam)
    },

    editExam(courseID, exam) {
      let exams = [...this.examsMap[courseID]]
      let editedExamIndex = exams.findIndex(_exam => _exam.ID === exam.ID)
      if(editedExamIndex > - 1)  exams[editedExamIndex] = new FormattedTextExam(exam).convert()
      this.examsMap[courseID] = exams
    },

    save() {
      let requests = []
      for(let cityID in this.cityMap) {
        for(let courseID in this.cityMap[cityID]) {
          this.examsMap[courseID].forEach(exam => {
            if(!this.activeExams.includes(exam.ID)) return
            let course = this.cityMap[cityID][courseID]
            requests.push({
              exam: exam,
              course: course
            })
          })
        }
      }
      requests = requests.sort((a ,b) => new Date(a.exam.date) - new Date(b.exam.date))
      this.$emit('save', requests)
      this.hideShowing()
    },

    selectWithShiftkey({event, item, index}) {
      if(!this.activeItems.length || !event.shiftKey) return
      this.extendedSelection({item, index})
    },

    extendedSelection({item, index}) {
      let activeItemsIndex = this.activeItems.map(itemID => this.courses.findIndex(_item => _item.ID === itemID))
      let itemUpperToSelectedIndex = -1
      activeItemsIndex.forEach(activeIndex => {
        if((index > activeIndex) && (itemUpperToSelectedIndex < activeIndex)) itemUpperToSelectedIndex = activeIndex
      })
      if(itemUpperToSelectedIndex < 0) return
      for(let i = itemUpperToSelectedIndex + 1; i < index; i++) {

        this.activeItems.push(this.courses[i].ID)
        this.switchExamShowing(this.courses[i])
      }
    }
  },

  components: {
    courseChoseDetailed: CourseChooseDetailed,
    courseItem: CourseItem,
    ReservationType,
    createCourseProvider: () => import('@/app/admin/modules/course_managment/components/cm_course/CMCreateCourseProvider.vue'),
    modalBody: ModalBody,
    MenuItem,
    courseValidator: () => import('@/app/admin/modules/course_managment/components/cm_course/CMCourseValidator.vue'),
    showingProvider: () => import('@/components/renderless/ShowingProvider.vue'),
    formErrorsProvider: () => import ('@/components/renderless/FormErrors.vue'),
    exam: () => import('./CandidateExamItem.vue'),
    examDataStore: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamDataStore.vue'),
    examBody: () => import('@/app/admin/modules/course_managment/components/cm_exam/CmExamBody.vue'),
    courseExamValidator: () => import('@/app/admin/modules/course_managment/components/cm_course/CMCourseExamValidator.vue'),
    ExamModalBody: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamModalBody.vue'),
    configCreateProvider: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamAddProvider.vue'),
    configCreateValidator: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMCreateValidationProvider.vue'),
    configEditProvider: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamEditProvider.vue'),
    CMServiceProvider: () => import('@/app/admin/modules/course_managment/components/CMServiceProvider.vue'),
    createCourseExam: () => import('@/app/admin/modules/course_managment/components/cm_course/CMCreateCourseExam.vue'),
    search: () => import('@/components/global/Search.vue')
  }
}
</script>

<style lang="scss" scoped>
.bulk-item {
  border-right: 1px solid $border-color;
  min-height: calc(100vh - 64px - 49px)
}

.bulk-city {
  position: relative;
  margin-bottom: 0;
  color: #232323;
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }

  &_active {
    color: $primary-color;
    &:before {
      position: absolute;
      bottom: 0;
      left: -10px;
      width: 3px;
      height: 100%;
      background-color: $primary-color;
      content: "";
    }
  }
}

.hide-bulk-search {
  opacity: 0;
}

.bulk-exam-title {
  @extend %title-subsection;
}

</style>
