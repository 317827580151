<template lang="pug">
v-col(cols="1").bulk-item
  v-list(flat)
    v-subheader COURSE TYPE
  menu-item(
    @click="select($options.COURSE_TYPE.OFFLINE)"
    :active="courseType === $options.COURSE_TYPE.OFFLINE"
  ) Offline
  menu-item(
     @click="select($options.COURSE_TYPE.ONLINE)"
    :active="courseType === $options.COURSE_TYPE.ONLINE"
  ) Online
</template>


<script>
import {COURSE_TYPE } from "../../../core/candidates.const"
import MenuItem from "./CandidateBulkReservationMenuItem.vue"
export default {
  COURSE_TYPE,
  props: {
    courseType: {
      required: true
    }
  },
  methods:{
    select(type) {
      this.$emit('click:type', type)
    }
  },

  components: {
    MenuItem
  }
}
</script>